import CrudService from "@/services/shared/http/CrudService";

export default class TicketService extends CrudService {
    constructor(params = {}) {
        super("tickets", params);
    }

    changeOrder(id, {status_id, order}) {
        return this.api.PATCH(`update-tickets-list/${id}`, {status_id, order});
    }

    getStatusOptions() {
        return this.api.GET('tickets-status-list')
    }

    getSourceOptions() {
        return this.api.GET('tickets-source-list')
    }

    getTagsOptions() {
        return this.api.GET('tickets-tags-list')
    }

    getSalesUsersOptions() {
        return this.api.GET('users-list?roles[]=Project_Manager')
    }
}
