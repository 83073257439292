<template>
  <b-link
      @click="$emit('cardClicked', item)"
      :to="{ name: 'sales-ticket-details', params: { ticket_id: item.id } }"
      class="d-flex kanban-card border-left-2 overflow-hidden"
  >
    <slot
        name="card"
        v-bind="item"
    >
      <slot
          name="card-header"
      >
        <div class="d-flex gap-10 flex-wrap mb-1">
          <span
              v-if="item.customer_existing_status"
              class="top-right-span"
              :style="{backgroundColor: item.customer_existing_status === 'New' ? '#90D24F' : '#7B93A9'}"
          >
            <span class="text-uppercase"
                  v-b-tooltip.top.hover
                  :title="item.customer_existing_status"
            >{{ item.customer_existing_status.slice(0, 1) }}</span>
          </span>
          <b-badge
              :variant="'light-primary'"
          >
            {{ item.order }}
          </b-badge>
          <b-badge
              :variant="'light-primary'"
          >
            {{ item.source.name }}
          </b-badge>
          <b-badge v-for="(tag, index) in item.tags"
                   :key="index"
                   :style="{backgroundColor: tag.color, color:'#fff'}"
          >
            {{ tag.name }}
          </b-badge>
          <b-badge
              v-if="item.other_tag"
              :variant="'primary'"
          >
            {{ item.other_tag }}
          </b-badge>
        </div>
      </slot>
      <slot
          name="card-body"
      >
        <div class="mb-1">
          <b-card-text class="font-weight-bold mb-0">
            {{ item.title }}
          </b-card-text>
          <b-card-img-lazy v-if="item.image" :src="require(`@/assets/images/slider/${item.image}`)"/>
        </div>
      </slot>
      <slot
          name="card-footer"
      >
        <div class="d-flex justify-content-between align-items-center">
          <div class="">
            <div class="d-flex" style="gap: 10px">
              <div class="">
                <Avatar :assignee="item.assignee"/>
              </div>
              <div
                  v-if="item.comments.length"
                  class="icon-div"
                  v-b-tooltip.hover
                  :title="'Comments'"
              >
                <feather-icon icon="MessageSquareIcon"/>
                <span>{{ item.comments.length }}</span>
              </div>
              <div
                  v-if="item.attachments"
                  class="icon-div"
                  v-b-tooltip.hover
                  :title="'Attachment'"

              >
                <feather-icon icon="PaperclipIcon"/>
                <span>{{ item.attachments.length }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex ml-auto mt-5px">
            <small
                v-b-tooltip.hover
                :title="getDate(item.created_at)"
                class="text-muted">
              <Timeago
                  class="ml-auto" :datetime="item.created_at"></Timeago>
            </small>
          </div>
        </div>

      </slot>
    </slot>
  </b-link>
</template>

<script>
import {
  BLink,
  BAvatar,
  BCardText,
  BAvatarGroup,
  BBadge,
  BCardImgLazy,
  VBTooltip,
} from "bootstrap-vue";
import Avatar from "./Avatar";

export default {
  name: "Card",
  components: {
    BLink,
    Avatar,
    BAvatar,
    BCardText,
    BAvatarGroup,
    BBadge,
    BCardImgLazy,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    item: undefined,
  },
  methods: {
    getDate(date) {
      return new Date(date).toLocaleString()
    }
  }
}
</script>

<style scoped>
.kanban-card {
  background: #fff;
  color: unset;
  border-radius: 0.428rem;
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 1rem 1.2rem;
  position: relative;
  transition: all .3s cubic-bezier(.23, 1, .32, 1), background 0s, color 0s, border 0s;
}

.top-right-span {
  position: absolute;
  top: -25px;
  right: -50px;
  background-color: #90D24F;
  color: #fff;
  font-size: 10px;
  transform: rotate(45deg);
  width: 100px;
  height: 50px;
  display: flex;
}

.top-right-span span {
  margin: auto auto 0;
}

.icon-div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.gap-10 {
  gap: 10px
}

.mt-5px {
  margin-top: 5px;
}
</style>
