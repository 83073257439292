<template>
  <div class="">
    <kanban/>
  </div>
</template>

<script>
import Kanban from "@/views/components/kanban/kanban";

export default {
  name: "SalesTickets",
  components: {
    Kanban,
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.card,
.card-body {
  background-color: transparent;
}

</style>
