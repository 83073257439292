<template>
  <div class="">
    <b-avatar
        v-if="assignee.length === 1"
        class="pull-up"
        v-b-tooltip.hover
        size="24px"
        :title="assignee[0].first_name + ' ' + assignee[0].last_name"
        :text="assignee[0].first_name.slice(0,1) + assignee[0].last_name.slice(0,1)"
        variant="primary"
    />
    <b-avatar-group v-if="assignee.length > 1" size="24px">
      <template
          v-for="(user, index) in assignee"
          class=""
      >
        <b-avatar
            v-if="user.avatar"
            class="pull-up"
            :key="index"
            v-b-tooltip.hover
            :title="user.first_name + ' ' + user.last_name"
            :src="require(`@/assets/images/portrait/small/${user.avatar}`)"
        />
        <b-avatar
            v-if="!user.avatar"
            class="pull-up"
            :key="index"
            v-b-tooltip.hover
            :title="user.first_name + ' ' + user.last_name"
            :text="user.first_name.slice(0,1) + user.last_name.slice(0,1)"
            variant="primary"
        />
      </template>
    </b-avatar-group>
  </div>
</template>

<script>
import {
  BAvatar,
  BAvatarGroup,
  BCardImgLazy,
  VBTooltip,
} from "bootstrap-vue";

export default {
  name: "Avatar",
  components: {
    BAvatar,
    BAvatarGroup,
    BCardImgLazy
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: ['assignee']
}
</script>

<style scoped>

</style>
