<template>
  <div class="">
    <b-form-group
        label="Comments"
        label-for="ticket-comments"
    >
      <div class="d-flex flex-column comments-list">

        <transition-group name="slide-fade">
          <div
              v-for="(comment,index) in commentsList"
              :key="index"
              class="card mb-1"
          >
            <div class="card-body">
              <div class="comment-card" v-html="comment.content"/>
              <div class="d-flex">
                <b-avatar
                    class="pull-up"
                    v-b-tooltip.hover
                    size="24px"
                    :title="userDate.first_name + ' ' + userDate.last_name"
                    :text="userDate.first_name.slice(0,1) + userDate.last_name.slice(0,1)"
                    variant="primary"
                />
                <small v-if="comment.created_at"
                    v-b-tooltip.hover
                    :title="getDate(comment.created_at)"
                    class="text-muted ml-auto align-self-end">
                  <Timeago
                      class="ml-auto" :datetime="comment.created_at"/>
                </small>
              </div>
            </div>
          </div>
        </transition-group>

      </div>
      <quill-editor
          id="quil-content"
          v-model="comment"
          :options="editorOption"
          class="border-bottom-0"
      />
      <div
          id="quill-toolbar"
          class="d-flex justify-content-end border-top-0"
      >
        <!--        &lt;!&ndash; Add a bold button &ndash;&gt;-->
        <!--        <button class="ql-bold"/>-->
        <!--        <button class="ql-italic"/>-->
        <!--        <button class="ql-background"/>-->
        <!--        <button class="ql-underline"/>-->
        <!--        <button class="ql-align"/>-->
        <!--        <button class="ql-link"/>-->
      </div>
      <b-button
          size="sm"
          class="mt-1"
          variant="primary"
          :disabled="!comment"
          @click="addComment"
      >
        Comment
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BButton, VBTooltip, BAvatar
} from 'bootstrap-vue'
import {quillEditor} from 'vue-quill-editor'
import {getUserData} from "@/auth/utils";

export default {
  name: "Comments",
  components: {
    BFormGroup, BButton, quillEditor, BAvatar
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'commentsList',
    event: 'update:newComment',
  },

  props: {
    commentsList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      userDate: getUserData(),
      comment: '',
      editorOption: {
        // docs link https://quilljs.com/docs/modules/toolbar
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{'color': []}, {'background': []}],          // dropdown with defaults from theme
          ],

        },
        placeholder: 'Write Your Comment',

      }
    }
  },
  methods: {
    addComment() {
      this.$emit('newComment', this.comment)
      this.commentsList.push({content: this.comment, created_at: new Date()})
      this.comment = ''
    },
    getDate(date) {
      return new Date(date).toLocaleString()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

#quil-content ::v-deep {
  display: flex;
  flex-direction: column-reverse;

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border: 1px solid #ccc;
    border-bottom: unset;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .ql-toolbar {
    border-top: unset;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.comments-list {
  max-height: 300px;
  padding: 10px;
  overflow: auto;
}

.comments-list .card-body {
  padding: 1rem;
}

.comment-card ::v-deep p {
  margin-bottom: 5px !important;
}
</style>
