import axios from '@axios'
import TicketService from "@/services/TicketService";

const ticketService = new TicketService()

export default {
    namespaced: true,
    state: {
        statusOptions: [],
        sourceOptions: [],
        tagsOptions: [],
        salesUsersOptions: [],
    },
    getters: {
        statusOptions: state => state.statusOptions,
        sourceOptions: state => state.sourceOptions,
        tagsOptions: state => state.tagsOptions,
        salesUsersOptions: state => state.salesUsersOptions,
    },
    mutations: {},
    actions: {
        getStatusOptions({state}, payload) {
            ticketService.getStatusOptions()
                .then(res => {
                    state.statusOptions = res.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getSourceOptions({state}, payload) {
            ticketService.getSourceOptions()
                .then(res => {
                    state.sourceOptions = res.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getTagsOptions({state}, payload) {
            ticketService.getTagsOptions()
                .then(res => {
                    state.tagsOptions = res.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getSalesUsersOptions({state}, payload) {
            ticketService.getSalesUsersOptions()
                .then(res => {
                    state.salesUsersOptions = res.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        addTicket(ctx, ticketData) {
            return new Promise((resolve, reject) => {
                ticketService
                    .create(ticketData)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateTicket(ctx, {ticket}) {
            return new Promise((resolve, reject) => {
                ticketService
                    .update(ticket.id, ticket)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        removeTicket(ctx, {id}) {
            return new Promise((resolve, reject) => {
                ticketService
                    .delete(id)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
    },
}
