<template>
  <div class="">
    <h6 class="text-primary font-weight-bold mb-2">
      <b-button
          class="w-100"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="flat-primary"
          @click="showInput = true"
      >
        <feather-icon
            icon="PlusIcon"
            class="mr-50"
        />
        <span>{{ btnText }}</span>
      </b-button>
    </h6>
    <div v-if="showInput" class="">
      <b-form-textarea
          v-if="inputType === 'textarea'"
          size="sm"
          v-model="text"
          class="mb-10px"
          :placeholder="placeholder"
          autofocus
          @keypress.enter="emitData"
      />
      <b-form-input
          v-if="inputType === 'text'"
          size="sm"
          v-model="text"
          class="mb-10px"
          :placeholder="placeholder"
          autofocus
          @keypress.enter="emitData"
      />
      <div class="">
        <b-button
            size="sm"
            class="mr-1"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            type="submit"
            @click="emitData"
        >Save
        </b-button>
        <b-button
            size="sm"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="cancelAddNewCol"
        >cancel
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {BButton, BFormInput, BFormTextarea} from "bootstrap-vue";

export default {
  name: "TextField",
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BFormInput,
    BFormTextarea
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    btnText: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: 'text' // textarea || text
    }
  },
  data() {
    return {
      showInput: false,
      text: undefined,
    }
  },
  methods: {
    emitData() {
      this.$emit('saveText', this.text)
      this.showInput = false
      this.text = undefined
    },
    cancelAddNewCol() {
      this.showInput = false
      this.text = undefined
    },
  }
}
</script>

<style scoped>
.mb-10px {
  margin-bottom: 10px;
}
</style>
