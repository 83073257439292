import {computed, ref, watch} from '@vue/composition-api'
import store from "@/store";

export default function useTicketHandler(props, emit) {
    // ------------------------------------------------
    // ticketLocal
    // ------------------------------------------------
    const ticketLocal = ref(JSON.parse(JSON.stringify(props.ticket.value)))
    const resetTicketLocal = () => {
        ticketLocal.value = JSON.parse(JSON.stringify(props.ticket.value))
    }
    watch(props.ticket, () => {
        resetTicketLocal()
    })

    // ------------------------------------------------
    // isEventHandlerSidebarActive
    // * Clear form if sidebar is closed
    // ! We can hide it using @hidden event
    // ------------------------------------------------
    // watch(props.isEventHandlerSidebarActive, val => {
    //   // ? Don't reset event till transition is finished
    //   if (!val) {
    //     setTimeout(() => {
    //       clearForm.value()
    //     }, 350)
    //   }
    // })

    const emitTicket = (checkAllFieldIsValid) => {
        if (checkAllFieldIsValid) {
            const ticketData = JSON.parse(JSON.stringify(ticketLocal))
            // console.log('ticketData.value.other_tag', ticketData.value.other_tag)
            // console.log('ticketData.value.tag_ids', ticketData.value.tag_ids)
            if (typeof ticketData.value.tag_ids[0] === "string") {
                ticketData.value.tag_ids = undefined
            }
            ticketData.value.comments = ticketData.value.comments.map(comment => comment.content)
            // * If event has id => Edit Event
            // Emit event for add/update event
            if (props.ticket.value.id) emit('update-ticket', ticketData.value)
            else emit('add-ticket', ticketData.value)
        }

        // Close sidebar
        // emit('update:is-ticket-handler-sidebar-active', false)
    }


    const tagsOptions = computed(() => store.getters["app-kanban/tagsOptions"])
    const statusOptions = computed(() => store.getters["app-kanban/statusOptions"])
    const sourceOptions = computed(() => store.getters["app-kanban/sourceOptions"])
    const salesUsersOptions = computed(() => store.getters["app-kanban/salesUsersOptions"])

    const newTag = (event) => {
        // console.log(event)
        ticketLocal.value.other_tag = event.name
        ticketLocal.value.tag_ids = []
    }

    return {
        ticketLocal,
        resetTicketLocal,

        emitTicket,
        tagsOptions,
        statusOptions,
        sourceOptions,
        salesUsersOptions,
        newTag,
    }
}
