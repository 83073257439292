const emit = (vnode, name, data) => {
    const handlers = (vnode.data && vnode.data.on) ||
        (vnode.componentOptions && vnode.componentOptions.listeners);

    if (handlers && handlers[name]) {
        handlers[name].fns(data);
    }
}
export const infinityScroll = {
    bind(el, binding, vnode) {
        el.addEventListener('scroll', e => {
            if ((el.scrollTop + el.clientHeight + 300 >= el.scrollHeight)) {
                emit(vnode, 'EndOfDiv', {});
            }
        });
    },
    unbind(el) {
        el.removeEventListener('scroll', e => {
        })
    },
}
