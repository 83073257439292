var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"sidebar-ticket-handler","sidebar-class":"sidebar-lg","visible":_vm.isTicketHandlerSidebarActive,"bg-variant":"white","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-ticket-handler-sidebar-active', val); },"hidden":_vm.clearData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[(_vm.ticketLocal.id)?_c('h5',{staticClass:"mb-0"},[_vm._v(" Edit Ticket ")]):_c('h5',{staticClass:"mb-0"},[_vm._v(" Create Ticket ")]),_c('div',[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.ticketLocal.id),expression:"ticketLocal.id"}],staticClass:"cursor-pointer",attrs:{"icon":"TrashIcon"},on:{"click":function($event){_vm.$emit('remove-ticket',_vm.ticketLocal.id); hide();}}}),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1)]),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"phone-number"}},[_c('validation-provider',{attrs:{"name":"phone number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"no-example":"","color":"#F4C522","error-color":"#EA5455","error":!!errors[0] || !_vm.isValidNumber,"state":(errors.length > 0) ? false : null,"default-country-code":"AE"},on:{"update":_vm.updatePhone},model:{value:(_vm.ticketLocal.title),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "title", $$v)},expression:"ticketLocal.title"}}),_c('b-form-invalid-feedback',{staticClass:"d-flex flex-column",attrs:{"state":errors.length > 0 ? false : null}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isValidNumber),expression:"!isValidNumber"}],staticClass:"text-danger"},[_vm._v("Phone Number Invalid")])])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Status","label-for":"status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"status-selector",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"placeholder":"Select Status","input-id":"status","state":errors.length > 0 ? false : null,"clearable":false,"reduce":function (status) { return status.id; },"label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex option-style",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
var color = ref.color;
return [_c('span',{staticClass:"d-flex pl-2 pr-2",style:({backgroundColor:color, color:'#fff'})},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.ticketLocal.status_id),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "status_id", $$v)},expression:"ticketLocal.status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('validation-provider',{attrs:{"name":"Tag","rules":!_vm.ticketLocal.other_tag ? 'required' :''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"close-on-select":true,"options":_vm.tagsOptions,"placeholder":"Select Tag","reduce":function (option) { return option.id; },"label":"name","input-id":"tags","taggable":"","multiple":"","create-option":function (tag) { return ({id:tag, name: tag }); }},on:{"option:created":_vm.newTag,"input":_vm.limitSelected},model:{value:(_vm.ticketLocal.tag_ids),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "tag_ids", $$v)},expression:"ticketLocal.tag_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Assignee","label-for":"assignee"}},[_c('validation-provider',{attrs:{"name":"Assignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"assignee-selector",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"close-on-select":true,"options":_vm.salesUsersOptions,"label":"first_name","placeholder":"Select Assignee","reduce":function (option) { return option.id; },"input-id":"assignee","taggable":"","multiple":""},on:{"input":_vm.limitSelected},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var avatar = ref.avatar;
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"26","src":avatar}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}},{key:"selected-option",fn:function(ref){
var avatar = ref.avatar;
var first_name = ref.first_name;
var last_name = ref.last_name;
return [_c('b-avatar',{attrs:{"size":"20","src":avatar,"text":_vm.avatarText(first_name+' '+ last_name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(first_name)+" "+_vm._s(last_name))])]}}],null,true),model:{value:(_vm.ticketLocal.assignee_ids),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "assignee_ids", $$v)},expression:"ticketLocal.assignee_ids"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Due Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Due Date","label-for":"due-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.flatConfig},model:{value:(_vm.ticketLocal.duo_to_date),callback:function ($$v) {_vm.$set(_vm.ticketLocal, "duo_to_date", $$v)},expression:"ticketLocal.duo_to_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('hr'),_c('Comments',{attrs:{"commentsList":_vm.ticketLocal.comments}}),_c('hr'),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.saving}},[(_vm.saving)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.ticketLocal.id ? (_vm.saving ? 'Updating' : 'Update') : (_vm.saving ? 'Saving' : 'Add '))+" ")],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }