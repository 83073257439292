<template>
  <div>
    <b-sidebar
        id="sidebar-ticket-handler"
        sidebar-class="sidebar-lg"
        :visible="isTicketHandlerSidebarActive"
        bg-variant="white"
        shadow
        backdrop
        no-header
        right
        @change="(val) => $emit('update:is-ticket-handler-sidebar-active', val)"
        @hidden="clearData"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <!--          <b-button-->
          <!--              v-if="ticketLocal.id"-->
          <!--              size="sm"-->
          <!--              :variant="ticketLocal.isCompleted ? 'outline-success' : 'outline-secondary'"-->
          <!--              @click="ticketLocal.isCompleted = !ticketLocal.isCompleted"-->
          <!--          >-->
          <!--            {{ ticketLocal.isCompleted ? 'Completed' : 'Mark Complete' }}-->
          <!--          </b-button>-->
          <h5
              v-if="ticketLocal.id"
              class="mb-0"
          >
            Edit Ticket
          </h5>
          <h5
              v-else
              class="mb-0"
          >
            Create Ticket
          </h5>
          <div>
            <feather-icon
                v-show="ticketLocal.id"
                icon="TrashIcon"
                class="cursor-pointer"
                @click="$emit('remove-ticket',ticketLocal.id); hide();"
            />
            <!--            <feather-icon-->
            <!--                class="ml-1 cursor-pointer"-->
            <!--                icon="StarIcon"-->
            <!--                size="16"-->
            <!--                :class="{ 'text-warning': ticketLocal.isImportant }"-->
            <!--                @click="ticketLocal.isImportant = !ticketLocal.isImportant"-->
            <!--            />-->
            <feather-icon
                class="ml-1 cursor-pointer"
                icon="XIcon"
                size="16"
                @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
            ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
              class="p-2"
              @submit.prevent="submit"
          >
            <!-- Title -->
            <b-form-group label="Phone Number" label-for="phone-number">
              <validation-provider #default="{ errors }" name="phone number" rules="required">

                <VuePhoneNumberInput
                    no-example
                    v-model="ticketLocal.title"
                    color="#F4C522"
                    error-color="#EA5455"
                    :error="!!errors[0] || !isValidNumber"
                    :state="(errors.length > 0) ? false : null"
                    default-country-code="AE"
                    @update="updatePhone"
                />
                <b-form-invalid-feedback class="d-flex flex-column" :state="errors.length > 0 ? false : null">
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-show="!isValidNumber">Phone Number Invalid</small>
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>


            <!--             Assignee -->
            <b-form-group
                label="Status"
                label-for="status"
            >
              <validation-provider #default="{ errors }" name="Status" rules="required">
                <v-select
                    v-model="ticketLocal.status_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    class="status-selector"
                    placeholder="Select Status"
                    input-id="status"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                    :state="errors.length > 0 ? false : null"
                    :clearable="false"
                    :reduce="status => status.id"
                    label="name"
                >

                  <template #option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                    <span class="d-flex option-style" :style="{backgroundColor:color, color:'#fff'}"> {{ name }}</span>
                  </template>

                  <template #selected-option="{ name, color }" :style="{backgroundColor:color, color:'#fff'}">
                    <span class="d-flex pl-2 pr-2" :style="{backgroundColor:color, color:'#fff'}"> {{ name }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!--Tag -->
            <b-form-group
                label="Tag"
                label-for="tag"
            >
              <validation-provider #default="{ errors }" name="Tag" :rules="!ticketLocal.other_tag ? 'required' :''">
                <v-select
                    v-model="ticketLocal.tag_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :close-on-select="true"
                    :options="tagsOptions"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                    placeholder="Select Tag"
                    :reduce="option => option.id"
                    label="name"
                    input-id="tags"
                    taggable
                    multiple
                    @option:created="newTag"
                    :create-option="tag => ({id:tag, name: tag })"
                    @input="limitSelected"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- assignee -->
            <b-form-group
                label="Assignee"
                label-for="assignee"
            >
              <validation-provider #default="{ errors }" name="Assignee" rules="required">
                <v-select
                    v-model="ticketLocal.assignee_ids"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''"
                    :close-on-select="true"
                    :options="salesUsersOptions"
                    label="first_name"
                    class="assignee-selector"
                    placeholder="Select Assignee"
                    :reduce="option => option.id"
                    input-id="assignee"
                    taggable
                    multiple
                    @input="limitSelected"
                >
                  <template #option="{ avatar, first_name, last_name }">
                    <b-avatar
                        size="26"
                        :src="avatar"
                    />
                    <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                  </template>

                  <template #selected-option="{ avatar, first_name, last_name }">
                    <b-avatar
                        size="20"
                        :src="avatar"
                        :text="avatarText(first_name+' '+ last_name)"
                    />

                    <span class="ml-50 d-inline-block align-middle"> {{ first_name }}  {{ last_name }}</span>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- due Date -->
            <validation-provider
                #default="{ errors }"
                name="Due Date"
                rules="required"
            >

              <b-form-group
                  label="Due Date"
                  label-for="due-date"
              >
                <flat-pickr
                    v-model="ticketLocal.duo_to_date"
                    class="form-control"
                    :config="flatConfig"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <!-- Comments -->
            <hr>
            <Comments
                :commentsList="ticketLocal.comments"
            />
            <hr>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="saving"
              >
                <b-spinner v-if="saving" small/>
                {{ ticketLocal.id ? (saving ? 'Updating' : 'Update') : (saving ? 'Saving' : 'Add ') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {avatarText} from '@core/utils/filter'
import {toRefs} from '@vue/composition-api'

import useTicketHandler from '../useTicketHandler'
import router from "@/router";
import VuePhoneNumberInput from 'vue-phone-number-input';
import Comments from "../components/Comments";
import ticketValidation from "@/views/components/kanban/useTicketValidation";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BSpinner,
    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    Comments
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isTicketHandlerSidebarActive',
    event: 'update:is-ticket-handler-sidebar-active',
  },
  props: {
    isTicketHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    ticket: {
      type: Object,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true
    },
    clearTicketData: {
      type: Function,
      required: true,
    },
  },
  setup(props, {emit}) {
    const {
      ticketLocal,
      resetTicketLocal,

      emitTicket,
      tagsOptions,
      statusOptions,
      sourceOptions,
      salesUsersOptions,
      newTag,
      addNewComment,
    } = useTicketHandler(toRefs(props), emit)

    const {
      refFormObserver,
      clearForm,
      checkValidNumber,
      isValidNumber,
      checkAllFieldIsValid,
      limitSelected
    } = ticketValidation(resetTicketLocal, props.clearTicketData)


    const updatePhone = (event) => {
      console.log(event)
      checkValidNumber(event)
      ticketLocal.value.formattedPhone = event.formattedNumber
    }
    const clearData = () => {
      clearForm()
      console.log()
      if (router.currentRoute.name !== 'sales-tickets')
        router.push({name: 'sales-tickets'})
    }

    const submit = async () => {
      emitTicket(await checkAllFieldIsValid())
    }

    const flatConfig = {
      minDate: "today"
    }
    return {
      submit,
      ticketLocal,
      emitTicket,
      updatePhone,

      // Form Validation
      checkValidNumber,
      isValidNumber,
      clearForm,
      refFormObserver,
      checkAllFieldIsValid,
      limitSelected,

      // Filter/Formatter
      avatarText,

      // resetSidebar
      clearData,

      // all select arrays
      tagsOptions,
      statusOptions,
      sourceOptions,
      salesUsersOptions,
      newTag,
      addNewComment,
      flatConfig,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~vue-phone-number-input/dist/vue-phone-number-input.css';
</style>

<style lang="scss" scoped>
//@import '~@core/scss/base/bootstrap-extended/include';
::v-deep .country-selector.input-country-selector.has-value.has-list-open {
  z-index: 20 !important;
}


.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

.option-style {
  padding: 5px;
  //border-radius: 0.358rem;
  justify-content: center;
}

::v-deep .status-selector .vs__dropdown-option {
  padding: 0;
  margin: 2px;
}
</style>
