import {ref, nextTick} from '@vue/composition-api'

// ===========================================================
// ! This is coupled with "veeValidate" plugin
// ===========================================================

export default function ticketValidation(resetFormData, clearFormData = () => {}) {
    // ------------------------------------------------
    // refFormObserver
    // ! This is for veeValidate Observer
    // * Used for veeValidate form observer
    // ------------------------------------------------
    const refFormObserver = ref(null)

    // ------------------------------------------------
    // resetObserver
    // ! This function is coupled with veeValidate
    // * It resets form observer
    // ------------------------------------------------
    const resetObserver = () => {
        refFormObserver.value.reset()
    }


    const isValidNumber = ref(true)
    const checkValidNumber = (event) => {
        console.log(event)
        isValidNumber.value = event.isValid
    }

    const checkAllFieldIsValid = () => {
        return refFormObserver.value.validate().then(success => {
            if (success) {
                return !!isValidNumber.value;
            } else {
                return false
            }
        })
    }


    const limitSelected = (e) => {
        if (e.length > 1) {
            e.shift()
        }
    }
    const clearForm = () => {
        clearFormData()
        nextTick(() => {
            resetObserver()
            isValidNumber.value = true
        })
    }
    return {
        refFormObserver,
        clearForm,
        checkValidNumber,
        isValidNumber,
        checkAllFieldIsValid,
        limitSelected
    }
}
