<template>
  <div class="kanban-container">
    <div class="d-flex justify-content-between align-items-center mb-1 header">
      <h3 class="mb-0">Sales Tickets</h3>
      <div>
        <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mr-1"
            :size="winnerWidth > 480 ? 'md': 'sm'"
            @click="showSidebar(null)"
        >
          <feather-icon
              icon="PlusIcon"
              class="mr-50"
          />
          <span>Add new ticket</span>
        </b-button>
        <feather-icon
            :icon="$store.state.appConfig.layout.menu.hidden ? 'MinimizeIcon' : 'MaximizeIcon'"
            :size="winnerWidth > 480 ? '20': '16'"
            class="cursor-pointer ml-auto"
            @click="changeView"
            v-b-tooltip.hover
            :title="$store.state.appConfig.layout.menu.hidden ? 'Minimize View' : 'Maximize View'"
        />
      </div>
    </div>
    <div class="mb-1 d-flex">
    </div>
    <div class="kanban">
      <b-spinner
          v-if="kanbanLoading"
          class="m-auto d-flex"
          style="width: 5rem; height: 5rem;"
          variant="primary"/>
      <div
          v-for="(column, index) in columns"
          class="kanban-col col-width d-flex flex-column"
          :key="index"
      >
        <div class="mb-1 d-flex align-items-center justify-content-between">
          <div class="">
            <h6 class="text-primary font-weight-bold mb-0">
              <b-form-input
                  @mouseover="column.editMode = true"
                  @focusout="column.editMode = false"
                  @click="column.editMode =true"
                  :debounce="1000"
                  @update="colTitleChanged($event, column)"
                  :plaintext="!options.editableHeaders"
                  class="header-input"
                  :class="!options.editableHeaders ? 'readonly' : ''"
                  v-model="column.title"
              />
            </h6>
          </div>
          <div class="">
            <span class="mr-1 font-medium-1">{{ column.totalItemsCount }}</span>
            <feather-icon
                @click="changeDir(column)"
                :style="{rotate:column.dir === 'desc' ? '180deg' : '0deg'}"
                class="transition cursor-pointer"
                :class="column.dir === 'desc' ? 'text-primary' : ''"
                size="18"
                icon="ArrowDownCircleIcon"
            />
          </div>
        </div>
        <div class="">
          <draggable
              @change="cardMoved($event,column)"
              v-model="column.items"
              class="list-group list-group-flush"
              tag="ul"
              ghost-class="ghost"
              group="people"
              :move="checkMove"
              v-bind="dragOptions"
              v-infinityScroll
              @EndOfDiv="loadMore(column)"
              :style="{maxHeight: height}"
              style="overflow: auto; scroll-behavior: smooth"
          >
            <transition-group
                type="transition"
                :name="'flip-list'"
            >
              <b-list-group-item
                  v-for="(item, itemIndex) in column.items"
                  :key="item.id"
                  tag="li"
              >
                <Card
                    @cardClicked="handleTicket($event.id)"
                    :style="{borderLeft:'2px solid '+ column.color, filter:item.canMove ? 'opacity(1)': 'opacity(0.5)' }"
                    :item="item"
                >
                </Card>
              </b-list-group-item>
            </transition-group>
          </draggable>
          <b-spinner v-if="column.loading" class="m-auto d-flex" variant="primary"/>
          <b-button
              v-if="options.addTicketByStatus && !column.loading"
              class="w-100"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="flat-primary"
              @click="showSidebar(column.id)"
          >
            <feather-icon
                icon="PlusIcon"
                class="mr-50"
            />
            <span>Add New Ticket</span>
          </b-button>
          <TextField
              v-if="options.addTicketInline && !column.loading"
              @saveText="addNewCard($event,column)"
              inputType="textarea"
              btnText="Add New Ticket"
              placeholder="Enter Card Title"
          />
        </div>
      </div>
      <div v-if="options.editableHeaders"
           class="kanban-col col-width"
      >
        <TextField
            v-if="options.extraCols"
            @saveText="addNewCol"
            btnText="Add New Column"
            inputType="text"
            placeholder="Enter Column Name"
        />
      </div>
    </div>
    <TicketHandlerSidebar
        v-model="isTicketHandlerSidebarActive"
        :saving="saveTicket"
        :ticket="ticket"
        :clear-ticket-data="clearTicketData"
        @remove-ticket="removeTicket"
        @add-ticket="addTicket"
        @update-ticket="updateTicket"
    />
  </div>
</template>

<script>
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import {BAvatar, BButton, BCardText, BCol, BFormInput, BListGroupItem, BRow, BSpinner, VBTooltip} from "bootstrap-vue";
import Prism from "vue-prism-component";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import TextField from "@/views/components/kanban/components/TextField";
import Card from "@/views/components/kanban/components/Card";
import {infinityScroll} from "./directives/infinityScroll";
import TicketHandlerSidebar from './components/TicketHandlerSidebar.vue'
// import * as data from './data.json'
import TicketService from "@/services/TicketService";
import kanbanStoreModule from "./store/kanbanStoreModule";
import {getUserData} from "@/auth/utils";

const defaultTicket = {
  id: null,
  title: '',
  formattedPhone: '',
  description: undefined,
  assignee_ids: [],
  status_id: null,
  tag_ids: [],
  source_id: 1,
  duo_to_date: new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getDate(),
  other_tag: undefined,
  order: 1,
  comments: []
}
export default {
  name: "kanban",
  components: {
    Card,
    TextField,
    BAvatar,
    BListGroupItem,
    BRow,
    BCol,
    Prism,
    draggable,
    BCardText,
    BButton,
    BFormInput,
    BSpinner,
    TicketHandlerSidebar
  },
  directives: {
    Ripple,
    infinityScroll,
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      options: {
        editableHeaders: false,
        addTicketInline: false,
        addTicketByStatus: true,
        extraCols: false,
      },
      columns: [],
      kanbanLoading: true,

      windowHeight: window.innerHeight,
      winnerWidth: window.innerWidth,
      TicketService: new TicketService(),
      isTicketHandlerSidebarActive: false,
      ticket: JSON.parse(JSON.stringify(defaultTicket)),
      saveTicket: false,
      clearTicketData: () => {
        this.ticket = JSON.parse(JSON.stringify(defaultTicket))
      }
    }
  },
  computed: {
    height() {
      if (this.$store.state.appConfig.layout.menu.hidden) {
        return (this.windowHeight - 230) + 'px'
      } else {
        return (this.windowHeight - 300) + 'px'
      }
    },
    // this value is used for cards animation
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    queryParam() {
      return this.$route.params.ticket_id
    }
  },
  beforeCreate() {
    if (!this.$store.hasModule('app-kanban')) this.$store.registerModule('app-kanban', kanbanStoreModule)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.TicketService.index().then(res => {
      console.log(res)
      this.fillData(res)
    }).finally(() => {
      this.kanbanLoading = false
    })
    console.log(this.queryParam)
    if (this.queryParam) this.handleTicket(this.queryParam)
    this.getLists()
  },
  methods: {
    test(event) {
      console.log(event)
    },
    checkMove(evt) {
      console.log(evt.draggedContext.element.canMove)
      return evt.draggedContext.element.canMove;
    },
    getLists() {
      this.getStatusOptions()
      this.getSourceOptions()
      this.getTagsOptions()
      this.getSalesUsersOptions()
    },
    getStatusOptions() {
      this.$store.dispatch('app-kanban/getStatusOptions',)
          .then(response => {
            console.log(response)
          })
    },
    getSourceOptions() {
      this.$store.dispatch('app-kanban/getSourceOptions',)
          .then(response => {
            console.log(response)
          })
    },
    getTagsOptions() {
      this.$store.dispatch('app-kanban/getTagsOptions',)
          .then(response => {
            console.log(response)
          })
    },
    getSalesUsersOptions() {
      this.$store.dispatch('app-kanban/getSalesUsersOptions',)
          .then(response => {
            console.log(response)
          })
    },
    changeView() {
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', !this.$store.state.appConfig.layout.menu.hidden)
      const type = this.$store.state.appConfig.layout.navbar.type
      this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', {type: type === 'hidden' ? 'floating' : 'hidden'})
    },
    fillData(data) {
      this.columns = []
      data.forEach(col => {
        const colData = {
          id: col.id,
          lastPage: col.data.last_page,
          page: 1,
          title: col.name,
          color: col.color,
          editMode: false,
          loading: false,
          totalItemsCount: col.data.total,
          dir: 'asc',
          items: []
        }
        col.data.data.forEach(item => {
          console.log(getUserData())
          if (item.assignee.length) {
            item.canMove = item.assignee[0].id === getUserData().id
          } else {
            item.canMove = false
          }
          // todo : remove the line below
          item.canMove = true
          colData.items.push(item)
        })
        this.columns.push(colData)
      })
    },
    loadMore(column) {
      if (column.loading) return
      if (column.lastPage === column.page) return;
      column.loading = true;
      this.TicketService.index({status_id: column.id, page: ++column.page, 'sort_dir[]': column.dir}).then(res => {
        console.log(res)
        res.data.data.forEach(item => {
          // todo : re-check can move
          // item.canMove = item.assignee[0].id === getUserData().id
          item.canMove = true
          column.items.push(item)
        })
        column.loading = false
      })
    },
    cardMoved(event, column) {
      console.log('event', event)
      console.log('column', column)
      if (event.added) {
        column.totalItemsCount++
        event.added.element.status_id = column.id
        column.items.forEach((item, index) => {
          if (column.dir === 'asc') {
            item.order = index + 1
          } else {
            item.order = column.totalItemsCount - index
          }
        })
        this.changeCardOrderApi(event.added.element)
      }
      if (event.removed) {
        column.totalItemsCount--
        column.items.forEach((item, index) => {
          if (column.dir === 'asc') {
            item.order = index + 1
          } else {
            item.order = column.totalItemsCount - index
          }
        })
      }
      if (event.moved) {
        column.items.forEach((item, index) => {
          if (column.dir === 'asc') {
            item.order = index + 1
          } else {
            item.order = column.totalItemsCount - index
          }
        })
        this.changeCardOrderApi(event.moved.element)
      }
    },
    changeCardOrderApi(card) {
      this.TicketService.changeOrder(card.id, {
        status_id: card.status_id,
        order: card.order
      }).then(res => {
        console.log(res)
      })

    },
    changeDir(column) {
      column.dir = column.dir === 'asc' ? 'desc' : 'asc'
      column.items = []
      column.page = 1
      column.loading = true
      this.TicketService.index({'sort_dir[]': column.dir, status_id: column.id, page: column.page}).then(res => {
        console.log(res.data.data)
        res.data.data.forEach(item => {
          // todo : re-check can move
          // item.canMove = item.assignee[0].id === getUserData().id
          item.canMove = true
        })
        column.items = res.data.data
        column.loading = false
      })
    },
    handleTicket(id) {
      // todo : update ticket
      // return
      this.TicketService.show(id).then(res => {
        console.log(res)
        res.comments = res.comments.map(com => com.content)
        this.ticket = res
      })
      this.isTicketHandlerSidebarActive = true

    },
    onResize() {
      this.windowHeight = window.innerHeight
      this.winnerWidth = window.innerWidth
    },
    addNewCol(event) {
      this.columns.push({
        id: this.columns.length + 1,
        dir: 'asc',
        color: '#176eb0',
        editMode: false,
        totalItemsCount: 0,
        title: event,
        items: []
      })
    },
    colTitleChanged(event, column) {
      column.title = event
    },
    addNewCard(event, column) {
      const data = {
        title: event,
        status_id: column.id,
        order: column.items.length + 1
      }
      if (column.dir === 'desc') {
        data.order = column.totalItemsCount
      }
      column.totalItemsCount++
      column.items.push(data)
    },
    addTicket(ticket) {
      console.log(ticket)
      // return
      ticket.title = ticket.formattedPhone
      this.saveTicket = true
      this.$store.dispatch('app-kanban/addTicket', ticket)
          .then(res => {
            console.log(res)
            this.columns.forEach(column => {
              if (column.id === ticket.status_id) {
                column.totalItemsCount++
                if (res.assignee.length) {
                  res.canMove = res.assignee[0].id === getUserData().id
                } else {
                  res.canMove = false
                }
                // todo : remove the line below
                res.canMove = true
                column.items.unshift(res)
                column.items.forEach((item, index) => {
                  if (column.dir === 'asc') {
                    item.order = index + 1
                  } else {
                    item.order = column.totalItemsCount - index
                  }
                })
                if (column.lastPage !== column.page) {
                  column.items.pop()
                }
              }
            })
            this.isTicketHandlerSidebarActive = false
          }).finally(() => {
        this.saveTicket = false

      })
    },
    removeTicket(id) {
      this.$store.dispatch('app-kanban/removeTicket', {id: id})
          .then(() => {

          })
    },
    updateTicket(ticketData) {
      this.$store.dispatch('app-kanban/updateTicket', {ticket: ticketData})
          .then(() => {

          })
    },
    showSidebar(statusId) {
      if (statusId) {
        this.ticket = JSON.parse(JSON.stringify(defaultTicket))
        this.ticket.status_id = statusId
      }
      this.isTicketHandlerSidebarActive = true
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
}
</script>

<style scoped>


::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  border-radius: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 6px;
}

.kanban-container {
  overflow: auto;
  /*padding: 1rem;*/
}

.kanban {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 100%;
  overflow: auto hidden;
}

.kanban-col {
  /*width: 300px;*/
}

.list-group-item {
  padding: 0;
  background: transparent;
  border: unset;
}

.col-width {
  width: 300px;
  min-width: 300px;
}

.list-group {
  min-height: 100px;
  padding: 10px 10px 10px 0;
}

.list-group span {
  /*background-color: #fff;*/
  min-height: 100px;
  display: flex;
  flex-direction: column;
}

/*.list-group span:has(li) {*/
/*  background-color: transparent;*/
/*}*/

.flip-list-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
  transition: all .5s;
}

.list-group-item i {
  cursor: pointer;
}

.header-input {
  background-color: transparent;
  border-color: transparent;
  transition: .3s;
}

.header-input:hover {
  border-color: #d8d6de;
  background-color: #fff;
}

.header-input:focus {
  border-color: #F4C522;
  background-color: #fff;
}

.header-input.readonly {
  background-color: transparent;
  border-color: transparent;
}

.transition {
  transition: .3s;
}

@media (max-width: 480px) {
  .header h3 {
    font-size: medium;
  }
}
</style>
